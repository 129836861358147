import "../App.css";
import Layout from "./Layout";
import * as Scroll from "react-scroll";
import { Link, ScrollLink } from "react-scroll";
import jwt_decode from "jwt-decode";

import "./datascienceStyles.css";
import Item1 from "../Images/Data Science/interface 21.png";
import newItem from "../Images/XMLID_15_ (3).png";
import newItem1 from "../Images/XMLID_15_ (4).png";
import newItem2 from "../Images/contactus.png";

import Item2 from "../Images/Data Science/Data science bubbles 2-01.png";
import Item3 from "../Images/Data Science/Data science bubbles 2-02.png";
import Item4 from "../Images/Data Science/Data science bubbles 2-03.png";
import Item5 from "../Images/Data Science/Data science bubbles 2-04.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function Datascience() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const navigate = useNavigate();

  // let user = localStorage.getItem("glascotoken");
  // useEffect(() => {
  //   if (user) {
  //     var decoded = jwt_decode(user);
  //     if (decoded.exp * 1000 < Date.now()) {
  //       navigate("/login");
  //       console.log("expired");
  //     }
  //   }
  //   if (!user) {
  //     navigate("/login");
  //   }
  // }, [user, navigate]);
  return (
    <Layout title="Datascience - Glasco Technologies">
      <div className="main2 text-white">
        <div className="grid md:grid-cols-10 md:gap-5">
          <div className="md:col-span-5 flex flex-col items-center justify-center md:mt-0">
            <div className="md:ml-44 sm:mt-5 vs ">
              <div className="md:mb-5 iconMargin">
                <img
                  src={newItem}
                  alt="datasciencelogo"
                  className="img2"
                  height={140}
                  width={140}
                ></img>
              </div>
              <h1 className="text-4xl">Data Science</h1>
              <h6 className="mt-5">
                Put data to work and do amazing things beyond the capacity of
                the human brain – things you never thought possible. Ask us
                about data science techniques that can be deployed and tools
                that can be built to illuminate your world and discover unique
                insights you can bank on. Let Glasco show you how data can light
                the way.
              </h6>
            </div>
          </div>
          <div className="flex items-center justify-center img md:mt-5 md:col-span-4">
            <img
              src={Item1}
              alt="item1"
              className="img1"
              height={520}
              width={520}
            ></img>
          </div>
        </div>
        <div className="flex  flex-col items-center justify-center text-center mt-1 href">
          <h3 className="mb-2">
            Become a data driven company with Glasco Technologies
          </h3>
          <Link to="process" spy={true} smooth={true} duration={500}>
            <img
              height={40}
              width={40}
              src={newItem1}
              alt=""
              className="mt-3"
            ></img>
          </Link>
        </div>
      </div>

      <div>
        <div className="flex flex-col items-center" id="process">
          <h1 className="process mt-5">Our Process</h1>
        </div>
        <div className="flex items-start md:ml-48 adjust1">
          <img src={Item2} alt="" height={520} width={520}></img>
        </div>
        <div className="flex justify-end md:mr-48 -mt-10">
          <img src={Item3} alt="" height={520} width={520}></img>
        </div>
        <div className="flex items-start md:ml-48">
          <img src={Item4} alt="" height={520} width={520}></img>
        </div>
        <div className="flex justify-end md:mr-48 adjust">
          <img src={Item5} alt="" height={520} width={520}></img>
        </div>
        <div className="flex flex-col items-end justify-end md:mr-48 md:-mt-10">
          <div className="para md:mt-10 mr-80 md:mb-28">
            <p className="">
              Become a data driven company with Glasco Technologies
            </p>
            <a href="/contact">
              <img
                height={200}
                width={250}
                src={newItem2}
                alt="item3"
                className="mt-8"
              ></img>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Datascience;
