import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "./Layout";
import axios from "axios";
const Signup = () => {
  const navigate = useNavigate();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;

    setUser({
      ...user,
      [name]: value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const url = "https://glascobackend.herokuapp.com/api/user";
      const { data: res } = await axios.post(url, user);
      navigate("/login");
      console.log(res);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
    }
    console.log(user);
  };

  return (
    <Layout title="Signup - Glasco Technologies">
      <div
        className="main4
       min-w-full min-h-full"
      >
        <div className="grid md:grid-cols-10 md:gap-5 other">
          <div className="md:col-span-5 mt-6 pro">
            <div className="max-w-screen-md md:mt-44 md:ml-44 pro1">
              <h1 className="text-4xl text-white mb-4">
                {" "}
                Make work fun. Get it done.
              </h1>
            </div>
          </div>
          <div className="md:col-span-5 flex flex-col items-start justify-start alignment mb-10 ">
            <form className="mt-16 mb-10 pro md:ml-6" onSubmit={submitHandler}>
              <h1 className="text-3xl mb-4 text-white">Signup Here !</h1>
              <div className="mb-4 flex flex-col">
                <label htmlFor="email" className="mb-2 text-white">
                  First Name
                </label>

                <input
                  type="text"
                  id="firstName"
                  className="w"
                  name="firstName"
                  required
                  value={user.firstName}
                  onChange={handleInput}
                />
              </div>
              <div className="mb-4 flex flex-col">
                <label htmlFor="email" className="mb-2 text-white">
                  Last Name
                </label>

                <input
                  type="text"
                  id="lastName"
                  className="w"
                  name="lastName"
                  required
                  value={user.lastName}
                  onChange={handleInput}
                />
              </div>
              <div className="mb-4 flex flex-col">
                <label htmlFor="email" className="mb-2 text-white">
                  Email
                </label>

                <input
                  type="email"
                  id="email"
                  className="w"
                  name="email"
                  required
                  value={user.email}
                  onChange={handleInput}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="password" className="mb-2 text-white">
                  Password
                </label>
                <input
                  name="password"
                  type="password"
                  className="w-full"
                  required
                  value={user.password}
                  onChange={handleInput}
                />
              </div>
              {error && (
                <div className="mb-2 mt-2 text-red-600 text-sm bg-white rounded-md text-center">
                  {error}
                </div>
              )}

              <div className="mb-4">
                <button
                  type="submit"
                  className="primary-button w-full text-white font-extrabold"
                  onClick={() => setLoading(!loading)}
                  style={{ backgroundColor: "#233F8E" }}
                >
                  {loading && !error ? (
                    <p>
                      Signing up... &nbsp; <i class="fa fa-spinner fa-spin"></i>
                    </p>
                  ) : (
                    "Signup"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Signup;
