import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Layout from "./Layout";
import "./contactStyles.css";

// hghjgg?
export const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const navigate = useNavigate();

  // let user = localStorage.getItem("glascotoken");
  // useEffect(() => {
  //   if (user) {
  //     var decoded = jwt_decode(user);
  //     if (decoded.exp * 1000 < Date.now()) {
  //       navigate("/login");
  //       console.log("expired");
  //     }
  //   }
  //   if (!user) {
  //     navigate("/login");
  //   }
  // }, [user, navigate]);

  const [name, setName] = useState();
  const [mobile, setMobile] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_5vpfm55",
        "template_cwii4x9",
        form.current,
        "Q1pBIuRpQaXBmxV6e"
      )
      .then(
        (result) => {
          console.log(result.text);
          setName("");
          setEmail("");
          setMessage("");
          setMobile("");
          setLoading(!loading);
          alert(`Thanks ${name}, Email sent successfully !`);
        },
        (error) => {
          console.log(error.text);
          alert(error.text);
        }
      );
  };

  return (
    <Layout title="Contact - Glasco Technologies">
      <div
        className="main4
       min-w-full min-h-full"
      >
        <div className="grid md:grid-cols-10 md:gap-5 other">
          <div className="md:col-span-5 mt-6 pro">
            <div className="max-w-screen-md mx-auto md:mt-44 marginTouch md:mr-20 pro1">
              <h1 className="text-4xl text-white mb-4">Get in Touch</h1>
              <h3 className="text-white font-semibold">
                Interested in working with us? We’d love to hear from you. Send
                us a note about what you’re thinking, and we’ll get back to you
                within a day or two.
              </h3>
            </div>
          </div>
          <div className="md:col-span-5 flex flex-col items-start justify-start alignment mb-10 ">
            <form
              className="mt-10 mb-10 pro md:ml-5"
              ref={form}
              onSubmit={sendEmail}
            >
              <h1 className="text-3xl mb-4 text-white">Contact Us !</h1>
              <div className="mb-4 flex flex-col">
                <label htmlFor="name" className="mb-0 text-white">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="w"
                  name="user_name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="mb-4">
                <label htmlFor="mobile" className="mb-2 text-white">
                  Mobile
                </label>

                <input
                  type="number"
                  id="mobile"
                  placeholder="+"
                  className="w-full"
                  name="user_mobile"
                  required
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>

              <div className="mb-4">
                <label htmlFor="email" className="mb-2 text-white">
                  Email
                </label>

                <input
                  type="email"
                  id="email"
                  className="w-full"
                  name="user_email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="mb-2 text-white">
                  Message
                </label>
                <textarea
                  name="message"
                  className="w-full"
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <button
                  type="submit"
                  value="Send"
                  className="primary-button w-full text-white font-extrabold"
                  onClick={() =>
                    name &&
                    mobile &&
                    email?.includes(
                      "/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/"
                    ) &&
                    message &&
                    setLoading(!loading)
                  }
                  style={{ backgroundColor: "#233F8E" }}
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

// Contact
