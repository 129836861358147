import React, { useEffect, useState } from "react";
import "./styles.css";
import { NavLink, useNavigate } from "react-router-dom";

import axios from "axios";
import { FaCheckCircle } from "react-icons/fa";

import SideBar from "../Sidebar/SideBar";
import Comments from "./Comments";
import { MdContactSupport } from "react-icons/md";
const Dashboard = () => {
  const navigate = useNavigate();
  const [loadin, setLoadin] = useState(true);
  const [modal, setModal] = useState();

  const [itemText, setItemText] = useState("");
  const [listItems, setListItems] = useState([]);
  const [users, setUsers] = useState([]);
  const [updateItemText, setUpdateItemText] = useState("");
  const [isUpdating, setIsUpdating] = useState("");
  const [startUpdating, setStartUpdating] = useState("");
  const [updateForm, setUpdateForm] = useState("");
  const [addload, setaddLoad] = useState(false);
  const [startLoad, setstartLoad] = useState(false);
  const [deleteload, setDeleteLoad] = useState(false);
  const [doneload, setDoneLoad] = useState(false);
  const [deletion, setDeletion] = useState(false);
  const [loggedIn, setLoggedIn] = useState({
    loggedIn: "",
    role: "",
  });
  const [assignedTo, setAssignedTo] = useState("");
  const [userId, setUserId] = useState("");
  const [count, setCount] = useState();
  const username = localStorage.getItem("glasconame");
  let date = new Date().toISOString().slice(0, 10);
  let wiindowWidth = window.innerWidth;

  useEffect(() => {
    const getItemsList = async () => {
      try {
        console.log(window.innerWidth);
        if (!localStorage.getItem("glascoId")) {
          window.location.replace("/login");
        }
        let userId1 = localStorage.getItem("glascoId");
        const url = `https://glascobackend.herokuapp.com/api/auth/single/${userId1}`;
        const result = await axios.get(url);

        setLoggedIn({ loggedIn: result.data.loggedIn, role: result.data.role });
        if (result.data.loggedIn === true) {
          const res = await axios.get(
            "https://glascobackend.herokuapp.com/todo/api/items"
          );

          setListItems(res.data.reverse());
          setLoadin(false);
          const usersall = await axios.get(
            "https://glascobackend.herokuapp.com/api/all"
          );
          setUsers(usersall.data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getItemsList();
  }, []);

  const setEmployees = (users) => {
    return users?.filter((item) => item.role !== 0);
  };

  const employees = setEmployees(users);
  //add new todo item to database
  const addItem = async (e) => {
    e.preventDefault();
    if (itemText && assignedTo) {
      try {
        const res = await axios.post(
          "https://glascobackend.herokuapp.com/todo/api/item",
          {
            item: itemText,
            assignedTo: assignedTo,
          }
        );

        const newList = [res.data, ...listItems];
        setListItems(newList);
        setItemText("");
        setAssignedTo("");
        setaddLoad(!addload);
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("Please add todo and assignee!!");
    }
  };

  //Create function to fetch all todo items from database -- we will use useEffect hook

  const logout = async (e) => {
    e.preventDefault();
    setLoggedIn(false);
    let userId = localStorage.getItem("userId");
    const url = `https://glascobackend.herokuapp.com/api/auth/${userId}`;
    const { data: res } = await axios.put(url, {
      loggedIn: false,
    });
    localStorage.removeItem("glascoId");
    localStorage.removeItem("glasconame");
    localStorage.removeItem("glascotoken");
    navigate("/login");
  };

  // Delete item when click on delete
  const deleteItem = async (id) => {
    try {
      const res = await axios.delete(
        `https://glascobackend.herokuapp.com/todo/api/item/${id}`
      );
      const newListItems = listItems.filter((item) => item._id !== id);
      setListItems(newListItems);
      setDeleteLoad(!deleteload);
    } catch (err) {
      console.log(err);
    }
  };

  //Update item
  const updateItem = async (id) => {
    try {
      const res = await axios.put(
        `https://glascobackend.herokuapp.com/todo/api/item/${id}`,
        {
          toDoStatus: true,
          doingStatus: true,
        }
      );
      console.log(res.data);

      const updatedItemIndex = listItems.findIndex((item) => item._id === id);

      const updatedItem = (listItems[updatedItemIndex].toDoStatus = true);
      const updatedItem1 = (listItems[updatedItemIndex].doingStatus = true);
      console.log(listItems);
      setstartLoad(!startLoad);
      setCount(res.data);
      setAssignedTo("");
    } catch (err) {
      console.log(err);
    }
  };
  //Done item
  const markDone = async (id) => {
    try {
      const res = await axios.put(
        `https://glascobackend.herokuapp.com/todo/api/item/${id}`,
        {
          toDoStatus: true,
          doingStatus: false,
          doneStatus: true,
        }
      );
      console.log(res.data);

      const updatedItemIndex = listItems.findIndex((item) => item._id === id);
      console.log(updatedItemIndex);
      const updatedItem = (listItems[updatedItemIndex].toDoStatus = true);
      const updatedItem1 = (listItems[updatedItemIndex].doingStatus = false);
      const updatedItem2 = (listItems[updatedItemIndex].doneStatus = true);
      const updatedItem3 = (listItems[updatedItemIndex].updatedAt = date);
      console.log(listItems);
      setCount(res.data);
      setDoneLoad(false);
    } catch (err) {
      console.log(err);
    }
  };

  const MakeComment = async (e, item) => {
    e.preventDefault();

    if (updateItemText) {
      try {
        document.getElementById("newcomment").innerHTML = "🚀";
        const res = await axios.put(
          `https://glascobackend.herokuapp.com/todo/api/comment/${isUpdating}`,
          {
            comment: ` ${updateItemText} -- ${
              username.charAt(0).toUpperCase() + username.slice(1)
            }  ${date}   `,
          }
        );

        const updatedItemIndex = listItems.findIndex(
          (item) => item._id === isUpdating
        );
        // console.log(updatedItemIndex);
        const updated = listItems[updatedItemIndex].comment.push(
          ` ${updateItemText} -- ${
            username.charAt(0).toUpperCase() + username.slice(1)
          }  ${date}`
        );
        setModal(item._id);
        setUpdateItemText("");
        setIsUpdating("");
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("Please leave a new comment !");
    }
  };

  const renderUpdateForm = (item) => (
    <form
      className="flex flex-row items-center justify-center"
      onSubmit={(e) => {
        MakeComment(e, item);
      }}
    >
      <input
        className="w-60 p-2 rounded"
        type="text"
        placeholder="Leave a comment"
        onChange={(e) => {
          setUpdateItemText(e.target.value);
        }}
        value={updateItemText}
      />
      <button
        className="text-white bg-amber-500 rounded px-4 py-1 ml-2 font-bold text-3xl"
        id="newcomment"
        type="submit"
      >
        👨🏻‍💻
      </button>
      <button
        className="text-white bg-amber-500 rounded px-4 py-1 ml-2 font-bold text-3xl"
        id="newcomment"
        type="submit"
        onClick={() => setIsUpdating(null)}
      >
        🙅‍♂️
      </button>
    </form>
  );
  return (
    <SideBar>
      {!loadin ? (
        <div className="m-5">
          {loggedIn.loggedIn === true ? (
            <a href="https://www.glascotechnologies.com">
              <button
                className="float-right bg-red-400 p-2 rounded text-white"
                onClick={logout}
              >
                {loggedIn === false ? "...." : "Logout"}
              </button>
            </a>
          ) : (
            ""
          )}
          {loggedIn.loggedIn === true && loggedIn.role === 1 ? (
            <div>
              <form
                className="form space-x-2 space-y-2"
                onSubmit={(e) => addItem(e)}
              >
                <input
                  className="p-1 ml-1 rounded infut"
                  type="text"
                  placeholder="Add Todo Item"
                  onChange={(e) => {
                    setItemText(e.target.value);
                  }}
                  value={itemText}
                />
                <select
                  value={assignedTo}
                  onChange={(e) => setAssignedTo(e.target.value)}
                  className="p-1 border border-gray-500 m-1 "
                >
                  <option>Select an employee</option>
                  {employees?.map((item) => {
                    return (
                      <option key={item.firstName}>
                        {item.role === 1 && item.firstName}
                      </option>
                    );
                  })}
                </select>
                <button
                  type="submit"
                  onClick={() => setaddLoad(!addload)}
                  className="p-2 bg-slate-400 rounded px-2 font-semibold text-white"
                >
                  {addload && assignedTo && itemText ? (
                    <p>
                      <i class="fa fa-spinner fa-spin"></i>
                    </p>
                  ) : (
                    "Add"
                  )}
                </button>
              </form>
              <div className="md:grid md:grid-cols-12 md:gap-20 md:mr-10 min-h-fit ">
                <div className="main-container1  md:mt-5 md:col-span-4  ">
                  <h1 className="text-4xl font-bold mb-5 mt-1 text-left md:mt-4">
                    Todo
                  </h1>
                  <div className="maiin mb-4">
                    <div className="">
                      <div className="">
                        {listItems.map((item) => {
                          return (
                            item.toDoStatus === false && (
                              <div
                                className="h-auto w-96 mt-2 bg-slate-400 rounded p-3"
                                key={item._id}
                              >
                                <p className="text-white min-w-full text-left font-bold mb-2 ">
                                  {item.item}
                                </p>
                                <div className="flex flex-row items-center justify-between">
                                  <div>
                                    <p className="text-white text-sm font-semibold pb-1.5">
                                      {" "}
                                      Assigned To {item.assignedTo}
                                    </p>
                                    <p className="text-white font-bold pb-2">
                                      {date === item.createdAt.slice(0, 10)
                                        ? "Today"
                                        : item.createdAt.slice(0, 10)}
                                    </p>
                                  </div>
                                  <div className="space-x-5">
                                    <button
                                      className="text-white bg-green-400 px-3 py-2 text-xs rounded-md font-bold "
                                      onClick={() => {
                                        updateItem(item._id);
                                        setstartLoad(!startLoad);
                                        setStartUpdating(item._id);
                                      }}
                                    >
                                      {startUpdating === item._id &&
                                      startLoad ? (
                                        <p>
                                          <i class="fa fa-spinner fa-spin"></i>
                                        </p>
                                      ) : (
                                        "Start"
                                      )}
                                    </button>
                                    <button
                                      className="text-white bg-red-400 p-2 text-xs rounded-md font-bold"
                                      onClick={() => {
                                        deleteItem(item._id);
                                        setDeletion(item._id);
                                        setDeleteLoad(!deleteload);
                                      }}
                                    >
                                      {deletion === item._id && deleteload ? (
                                        <p>
                                          <i class="fa fa-spinner fa-spin"></i>
                                        </p>
                                      ) : (
                                        "Delete"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-container1  md:mt-9 md:col-span-4">
                  <h1 className="text-4xl font-bold mb-5 text-left">
                    In Progress
                  </h1>
                  <div className="maiin mb-4">
                    <div className="">
                      <div className="">
                        {listItems.map((item) => {
                          return (
                            item.toDoStatus === true &&
                            item.doingStatus === true && (
                              <div
                                className="h-auto w-96 mt-2 bg-slate-400 rounded space-y-1 p-3"
                                key={item._id}
                              >
                                {isUpdating === item._id ? (
                                  renderUpdateForm(item)
                                ) : (
                                  <div>
                                    {modal ? (
                                      <Comments
                                        item={item}
                                        modal={modal}
                                        setModal={setModal}
                                        isUpdating={isUpdating}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    <p className="text-white min-w-full text-left font-bold mb-2.5 ">
                                      {item.item}
                                      {item.comment !== undefined && (
                                        <>
                                          <p
                                            className="text-xs text-yellow-300 underline cursor-pointer"
                                            onClick={() =>
                                              item.comment.length >= 0
                                                ? setModal(item._id)
                                                : null
                                            }
                                          >
                                            Comments
                                            {item.comment.length > 0
                                              ? ` (${item.comment.length})`
                                              : ""}
                                          </p>
                                        </>
                                      )}
                                    </p>

                                    <div className="flex flex-row items-center justify-between">
                                      <div>
                                        <p className="text-white text-sm font-semibold">
                                          {" "}
                                          Assigned To {item.assignedTo}
                                        </p>
                                        <p className="text-xs text-white font-bold pb-1">
                                          {date === item.createdAt.slice(0, 10)
                                            ? "Today"
                                            : item.createdAt.slice(0, 10)}
                                        </p>
                                      </div>

                                      <div className="space-x-5">
                                        <button
                                          className="text-white bg-green-400 p-2 text-xs rounded-md font-bold"
                                          onClick={() => {
                                            markDone(item._id);
                                            setDoneLoad(!doneload);
                                            setUpdateForm(item._id);
                                          }}
                                        >
                                          {updateForm === item._id &&
                                          doneload ? (
                                            <p>
                                              <i class="fa fa-spinner fa-spin"></i>
                                            </p>
                                          ) : (
                                            "Mark Done"
                                          )}
                                        </button>
                                        <button
                                          className="text-white bg-green-400 p-2 text-xs rounded-md font-bold"
                                          onClick={() =>
                                            setIsUpdating(item._id)
                                          }
                                        >
                                          Comment
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-container1  md:mt-9 md:col-span-4  ">
                  <h1 className="text-4xl font-bold mb-5 text-left">Done</h1>
                  <div className="maiin ">
                    <div className="">
                      <div className="">
                        {listItems.map((item) => {
                          return (
                            item.toDoStatus === true &&
                            item.doingStatus === false &&
                            item.doneStatus === true && (
                              <div
                                className="h-auto w-96 mt-2 bg-slate-300 rounded p-3 space-y-0.5 "
                                key={item._id}
                              >
                                {modal ? (
                                  <Comments
                                    item={item}
                                    modal={modal}
                                    setModal={setModal}
                                    isUpdating={isUpdating}
                                  />
                                ) : (
                                  ""
                                )}
                                <div className="text-green-500">
                                  <FaCheckCircle />
                                </div>
                                <p className="text-green-500 min-w-full text-left font-semibold  ">
                                  {item.item}
                                </p>
                                {item.comment !== undefined && (
                                  <>
                                    <p
                                      className="text-xs text-green-500 font-semibold underline cursor-pointer py-0.5"
                                      onClick={() =>
                                        item.comment.length >= 0
                                          ? setModal(item._id)
                                          : null
                                      }
                                    >
                                      Comments
                                      {item.comment.length > 0
                                        ? ` (${item.comment.length})`
                                        : ""}
                                    </p>
                                  </>
                                )}
                                <div className="flex flex-row items-center justify-between">
                                  <p className="text-green-500 font-bold">
                                    Completed By {item.assignedTo}
                                  </p>
                                  <p className="text-green-500 font-semibold">
                                    {date === item.updatedAt.slice(0, 10)
                                      ? "Today"
                                      : item.updatedAt.slice(0, 10)}
                                  </p>
                                </div>
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="ml-96 mt-80">Access denied!</div>
          )}
        </div>
      ) : (
        <div
          className="flex  mt-72"
          style={
            wiindowWidth >= 830
              ? { marginLeft: "600px", fontSize: "30px" }
              : { marginLeft: "300px", fontSize: "30px" }
          }
        >
          <i class="fa fa-spinner fa-spin"></i>
        </div>
      )}
    </SideBar>
  );
};

export default Dashboard;
