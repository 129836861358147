import React, { useEffect } from "react";
import Landing from "./components/Landing";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import WebsiteDesDev from "./components/WebsiteDesDev";
import Datascience from "./components/Datascience";
import { SoftwareDev } from "./components/SoftwareDev";
import { Contact } from "./components/Contact";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Authorize from "./components/Authorize";
import Dashboard from "./components/pages/Dashboard";
import MyTasks from "./components/pages/MyTasks";
import VisualDesign from "./components/VisualDesign";
import Support from "./components/Support";

function App() {
  return (
    <Routes>
      <Route path="/" exact element={<Landing />} />
      <Route path="/websiteDesignandDev" exact element={<WebsiteDesDev />} />
      <Route path="/datascience" exact element={<Datascience />} />
      <Route path="/Softwaredevelopment" exact element={<SoftwareDev />} />
      <Route path="/contact" exact element={<Contact />} />
      <Route path="/login" exact element={<Login />} />
      <Route path="/signup" exact element={<Signup />} />
      <Route path="/authorize" exact element={<Authorize />} />
      <Route path="/dashboard" exact element={<Dashboard />} />
      <Route path="/mytasks" exact element={<MyTasks />} />
      <Route path="/visualdesign" element={<VisualDesign />} />
      <Route path="/services-support" element={<Support />} />
    </Routes>
  );
}

export default App;
