import React, { useEffect } from "react";
import Layout from "./Layout";
import * as Scroll from "react-scroll";
import { Link, ScrollLink } from "react-scroll";
import jwt_decode from "jwt-decode";

import "./softwareDevStyles.css";
import Item1 from "../Images/Software Development/interface 20.png";
import newItem1 from "../Images/contactus.png";

import newItem from "../Images/softwareDevLogo.png";
import Item2 from "../Images/Software Development/Software Development 2-01.png";
import Item3 from "../Images/Software Development/Software Development 2-02.png";
import Item4 from "../Images/Software Development/Software Development 2-03.png";
import { useNavigate } from "react-router-dom";

export const SoftwareDev = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const navigate = useNavigate();

  // let user = localStorage.getItem("glascotoken");
  // useEffect(() => {
  //   if (user) {
  //     var decoded = jwt_decode(user);
  //     if (decoded.exp * 1000 < Date.now()) {
  //       navigate("/login");
  //       console.log("expired");
  //     }
  //   }
  //   if (!user) {
  //     navigate("/login");
  //   }
  // }, [user, navigate]);
  return (
    <Layout title="SoftwareDev - Glasco Technologies">
      <div className="main3 text-white">
        <div className="grid md:grid-cols-10 md:gap-5">
          <div className="md:col-span-5 flex flex-col items-center justify-center md:mt-10">
            <div className="md:ml-44 sm:mt-5 vs ">
              <div className="md:mb-5 iconMargin">
                <img
                  src={newItem}
                  alt="item2"
                  className="img2"
                  height={140}
                  width={140}
                ></img>
              </div>
              <h1 className="text-4xl">Software Development</h1>
              <h6 className="mt-5">
                If you have a concept for a custom software, chances are we can
                build it for you. Recruiting the right team members for these
                project can take a lot of time, energy and money to do it
                right.Our industry experienced team can quickly provide a
                cost-effective assessment and execute your project goals for
                you. Glasco’s highly skilled developers use the newest
                technologies to build software from all stages.We take abstract
                ideas from the seed level to functional prototypes then polish
                them into ready-to-market version. We also improve and re-design
                existing software, starting from whatever partially developed
                stage they’re currently at.
              </h6>
            </div>
          </div>
          <div className="flex items-center justify-center img md:mt-12 md:col-span-4">
            <img
              src={Item1}
              alt="item1"
              className="img1"
              height={550}
              width={550}
            ></img>
          </div>
        </div>
      </div>

      <div>
        <div className="flex flex-col items-center" id="process">
          <h1 className="process mt-5">Our Process</h1>
        </div>
        <div className="flex items-start md:ml-64 adjust1">
          <img src={Item2} alt="" height={520} width={520}></img>
        </div>
        <div className="flex justify-end md:mr-64 -mt-10">
          <img src={Item3} alt="" height={520} width={520}></img>
        </div>
        <div className="flex items-start md:ml-64">
          <img src={Item4} alt="" height={520} width={520}></img>
        </div>
        <div className="flex flex-col items-end justify-end md:mr-48 md:-mt-10">
          <div className="para lg:-mt-28 md:mr-52 md:mb-16">
            <p className="">
              Contact Glasco to develop that software you keep thinking about
            </p>
            <a href="/contact">
              <img
                height={200}
                width={250}
                src={newItem1}
                alt="item3"
                className="mt-8"
              ></img>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};
