import { data } from "autoprefixer";
import axios from "axios";
import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

const Authorize = () => {
  const navigate = useNavigate();
  const [error, setError] = useState();

  const handler = async (e) => {
    e.preventDefault();
    try {
      let userId = localStorage.getItem("glascoId");

      if (!userId) {
        alert("Please Login");
      } else {
        const url = `https://glascobackend.herokuapp.com/api/auth/${userId}`;
        const { data: res } = await axios.put(url, {
          loggedIn: true,
        });

        if (data) {
          window.location.replace("/dashboard");
        } else {
          navigate("/login");
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
    }
  };
  return (
    <div className="flex flex-col items-center justify-center mt-80">
      <button onClick={handler} className="bg-green-500 p-2 rounded">
        Authorize Todo Application
      </button>
    </div>
  );
};

export default Authorize;
