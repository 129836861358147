import React, { useEffect } from "react";
import Layout from "./Layout";
import Image1 from "../Images/Visual Design/icon.png";
import Image2 from "../Images/Visual Design/poster.png";
import Image3 from "../Images/Visual Design/logodesign.png";
import Image4 from "../Images/Visual Design/CompositeLayer.png";
import Image5 from "../Images/Visual Design/brandp.png";
import Image6 from "../Images/Visual Design/Steem.png";
import Image7 from "../Images/Visual Design/images+content.png";
import Image8 from "../Images/Visual Design/erik.jpg";
import Image9 from "../Images/Visual Design/infographics.png";
import Image10 from "../Images/Visual Design/infographivs1.png";
import Image11 from "../Images/Visual Design/prototyping.png";
import Image12 from "../Images/Visual Design/prototyping1.png";
import Image13 from "../Images/Visual Design/consumer.png";
import Image14 from "../Images/Visual Design/consumer products.jpg";
import Image15 from "../Images/Visual Design/MU 5-2.png";
import Image16 from "../Images/Visual Design/sirrup.png";
import Image17 from "../Images/Visual Design/8.JPG";
import Image18 from "../Images/Visual Design/Group 967.png";
import Image19 from "../Images/Visual Design/Group 963.png";
import Image20 from "../Images/Visual Design/Group 965.png";
import Image21 from "../Images/Visual Design/Group 962.png";

import "./visualDesignStyles.css";
const VisualDesign = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout title="VisualDesign - Glasco Technologies">
      <div className="VisualMain text-black">
        <div className="grid md:grid-cols-10 md:gap-5 lg:p-2 pl-8 pr-8">
          <div className="md:col-span-5 ">
            <div className="lg:ml-44  md:ml-39 mt-20">
              <img src={Image1} height={140} width={140} alt="icon"></img>
              <h1 className="text-4xl mt-5 font-semibold">Visual Design</h1>
              <h6 className="mt-3 ">
                When people see clean interesting designs, they’re gripped, and
                they want to find out more. Achieving this kind of visual appeal
                consistently is something Steem has become known for. Our team's
                iterative process has a proven track record of creating
                impactful novel designs - we can also work with you to gather
                elements and ideas from your imagination and pull them into a
                cohesive finished design.
              </h6>
            </div>
          </div>
          <div className="md:col-span-5 lg:ml-16 md:mt-28 lg:mt-2">
            <img src={Image2} height={500} width={500} alt="images"></img>
          </div>
        </div>
      </div>
      <div>
        <div className="grid md:grid-cols-10 md:gap-5 pt-8  pb-8 md:pl-0 md:pr-0 pl-8 pr-8">
          <div className="flex md:col-span-5 flex-col md:ml-44 md:mt-16 space-y-5">
            <img src={Image3} height={200} width={200} alt="images"></img>
            <h1 className="text-3xl font-bold">Logo Design</h1>
            <h6 className="">
              Business logo design is an important tool when it comes to
              promoting a company's products or services. But the logo must have
              a unique design that incorporates a design concept and colors etc.
              elements in a special way. Such a logo makes a lasting positive
            </h6>
          </div>
          <div className="md:col-span-5 mt-5">
            <img src={Image4} height={600} width={600} alt="jkio"></img>
          </div>
        </div>
        <div className="grid md:grid-cols-10 md:gap-5 pt-8  pb-8 md:pl-0 md:pr-0 pl-8 pr-8">
          <div className="flex md:col-span-5 flex-col md:ml-44 md:mt-16 space-y-5">
            <img src={Image5} height={150} width={150} alt="images"></img>
            <h1 className="text-3xl font-bold">Brand Packages</h1>
            <h6 className="">
              According to Business Dictionary, a brand is a unique design,
              sign, symbol, words, or a combination of these, employed in
              creating an image that identifies a product and differentiates it
              from its competitors. In other words, a brand is a visual
              representa
            </h6>
          </div>
          <div className="md:col-span-5 mt-5">
            <img src={Image6} height={600} width={600} alt="jkio"></img>
          </div>
        </div>
        <div className="grid md:grid-cols-10 md:gap-5 pt-8  pb-8 md:pl-0 md:pr-0 pl-8 pr-8">
          <div className="flex md:col-span-5 flex-col md:ml-44 md:mt-16 space-y-5">
            <img src={Image7} height={130} width={130} alt="images"></img>
            <h1 className="text-3xl font-bold">Images + Content</h1>
            <h6 className="">
              Your brand image is strongly tied to your content. The sweet spot
              between branding and content can be the tone and visuals that you
              use in your marketing and advertising material. These can then be
              carried forward in all your content.
            </h6>
          </div>
          <div className="md:col-span-5 mt-5">
            <img
              src={Image8}
              className="rounded-lg"
              height={600}
              width={600}
              alt="jkio"
            ></img>
          </div>
        </div>
        <div className="grid md:grid-cols-10 md:gap-5 pt-8  pb-8 md:pl-0 md:pr-0 pl-8 pr-8">
          <div className="flex md:col-span-5 flex-col md:ml-44 md:mt-16 space-y-5">
            <img src={Image9} height={200} width={200} alt="images"></img>
            <h1 className="text-3xl font-bold">Icons + Info Graphics</h1>
            <h6 className="">
              Icons, infographics and illustrations can be used to communicate a
              message quickly and simply. They allow you to cut through the
              noise and get simple information across quickly. Any new
              illustrations and infographics may be created with advice and
              approval
            </h6>
          </div>
          <div className="md:col-span-5 mt-12">
            <img src={Image10} height={600} width={600} alt="jkio"></img>
          </div>
        </div>
        <div className="grid md:grid-cols-10 md:gap-5 pt-8  pb-8 md:pl-0 md:pr-0 pl-8 pr-8">
          <div className="flex md:col-span-5 flex-col md:ml-44 md:mt-16 space-y-5">
            <img src={Image11} height={200} width={200} alt="images"></img>
            <h1 className="text-3xl font-bold">Digital Prototyping</h1>
            <h6 className="">
              Developing a digital prototype can help you test-drive ideas for
              your digital product or service in a fast, safe, and
              cost-effective way. Beyond this, it’s a critical business tool for
              driving innovation in your organisation.
            </h6>
          </div>
          <div className="md:col-span-5 mt-5">
            <img src={Image12} height={600} width={600} alt="jkio"></img>
          </div>
        </div>
        <div className="grid md:grid-cols-10 md:gap-5 pt-8  md:pb-28 pb-8 md:pl-0 md:pr-0 pl-8 pr-8">
          <div className="flex md:col-span-5 flex-col md:ml-44 md:mt-16 space-y-5">
            <img src={Image13} height={80} width={80} alt="images"></img>
            <h1 className="text-3xl font-bold">
              Consumer Products,Physical Prototyping, Sourcing and Manufacturing
            </h1>
            <h6 className="">
              product sourcing is the process of finding products that you can
              sell through your business. The source of products may be either
              domestic or international. Determining your niche business and
              market, etc.
            </h6>
          </div>
          <div className="md:col-span-5 md:mt-20 mt-5">
            <img src={Image14} height={600} width={600} alt="jkio"></img>
          </div>
        </div>
        <h1 className="text-3xl font-bold bg-slate-300 text-center pt-8 md:pl-8 text-[#24408D]">
          Featured Designs
        </h1>
        <div className="grid md:grid-cols-10 md:gap-5 pt-8  pb-8 md:pl-0 md:pr-0 pl-8 pr-8 bg-slate-300">
          <div className="md:col-span-5 flex flex-col md:ml-44 space-y-3 pb-5">
            <img src={Image15} height={600} width={600} alt="images"></img>
            <img src={Image16} height={600} width={600} alt="images"></img>
          </div>
          <div className="md:col-span-5 flex flex-col md:ml-10 mt-1">
            <img
              className="rounded-lg"
              src={Image17}
              height={400}
              width={470}
              alt="images"
            ></img>
          </div>
        </div>
        <div className="  bg-slate-300">
          <div className="flex flex-wrap items-center space-y-4 pb-20 md:space-x-8 md:pr-20  justify-center ">
            <img src={Image18} alt="images"></img>
            <img src={Image19} alt="images"></img>
            <img src={Image20} alt="images"></img>
            <img src={Image21} alt="images"></img>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VisualDesign;
