import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../Images/glasco_logo.png";
import "./layoutStyles.css";
import jwt_decode from "jwt-decode";

const Layout = ({ title, children }) => {
  const user = localStorage.getItem("glasconame");
  console.log(user);
  const windowWidth = window.innerWidth;
  const navigate = useNavigate();
  const handleLogout = async () => {
    let userId = localStorage.getItem("glascoId");
    const url = `https://glascobackend.herokuapp.com/api/auth/${userId}`;
    const { data: res } = await axios.put(url, {
      loggedIn: false,
    });
    localStorage.removeItem("glascotoken");
    localStorage.removeItem("glasconame");
    localStorage.removeItem("glascoId");
    navigate("/login");
  };
  // document.title("Aaaaaa");
  useEffect(() => {
    let user = localStorage.getItem("glascotoken");
    if (user) {
      var decoded = jwt_decode(user);
      if (decoded.exp * 1000 < Date.now()) {
        navigate("/login");
        handleLogout();
      }
    }

    document.title = title;
  }, [title, user, navigate]);
  return (
    <>
      <div className="flex flex-col justify-between min-h-screen">
        {windowWidth > 1280 ? (
          <header>
            <nav className="flex h-24 items-center justify-between px-4">
              <a href="/" className=" font-bold md:ml-40 ">
                <img
                  src={Logo}
                  alt="Glasco"
                  height={200}
                  width={200}
                  className="bg-slate-200"
                ></img>
              </a>
              <div
                className="flex flex-col justify-between text-white text-sm mr-48"
                id="mar"
                style={{ fontFamily: "Nexa", fontWeight: "400" }}
              >
                <div className="text-center text-xs ml-10 flex flex-row space-x-5 items-center justify-center">
                  <NavLink to="/contact">Contact Us</NavLink>
                  {user ? (
                    <div className="flex flex-row space-x-5 items-center justify-center">
                      <p className="space-x-5" style={{ cursor: "pointer" }}>
                        {" "}
                        Hi {user.charAt(0).toUpperCase() + user.slice(1)}
                      </p>
                      <p onClick={handleLogout} style={{ cursor: "pointer" }}>
                        Logout
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="text-xs mt-5 App-navbar ">
                  <NavLink
                    to="/websiteDesignandDev"
                    className={({ isActive }) =>
                      isActive ? "activeLink px-4 " : " inactive px-4"
                    }
                  >
                    WEBSITE DESIGN & DEVELOPMENT
                  </NavLink>
                  <NavLink
                    to="/datascience"
                    className={({ isActive }) =>
                      isActive ? "activeLink px-4 " : " inactive px-4"
                    }
                  >
                    DATA SCIENCE
                  </NavLink>
                  <NavLink
                    to="/visualdesign"
                    className={({ isActive }) =>
                      isActive ? "activeLink px-4 " : " inactive px-4"
                    }
                  >
                    VISUAL DESIGN
                  </NavLink>
                  <NavLink
                    to="/softwaredevelopment"
                    className={({ isActive }) =>
                      isActive ? "activeLink px-4 " : " inactive px-4"
                    }
                  >
                    SOFTWARE DEVELOPMENT
                  </NavLink>
                  <NavLink
                    to="/services-support"
                    className={({ isActive }) =>
                      isActive ? "activeLink px-4 " : " inactive px-4"
                    }
                  >
                    SERVICES & SUPPORT
                  </NavLink>
                </div>
              </div>
            </nav>
          </header>
        ) : (
          <section class="top-nav">
            <div className="flex flex-row">
              <a href="/" className="text-2xl font-bold mr-8">
                <img
                  className="ml-7 bg-slate-200"
                  height={120}
                  width={120}
                  Layout="fill"
                  src={Logo}
                  alt="Glasco"
                ></img>
              </a>
            </div>
            <input id="menu-toggle" type="checkbox" />
            <label class="menu-button-container" for="menu-toggle">
              <div class="menu-button mr-14 mt-9"></div>
            </label>
            <ul class="menu">
              <li>
                <a href="/websiteDesignandDev">WEBSITE DESIGN & DEVELOPMENT</a>
              </li>
              <li>
                <a href="/datascience">DATASCIENCE</a>
              </li>
              <li>
                <a href="/visualdesign">VISUAL DESIGN</a>
              </li>
              <li>
                <a href="/softwaredevelopment">SOFTWARE DEVELOPMENT</a>
              </li>
              <li>
                <a href="/contact">CONTACT US</a>
              </li>
              <li>
                <a href="/services-support">SERVICES & SUPPORT</a>
              </li>
            </ul>
          </section>
        )}
        <main>{children}</main>
        <footer
          className="flex h-56 justify-center items-center small "
          style={{ backgroundColor: "#414042" }}
        >
          <div className="flex items-center justify-center md:ml-20 md:mb-8 small mt-5">
            <div
              className="text-white text-right border-r-2 "
              style={{ fontSize: "15px", fontWeight: "bold" }}
            >
              <ul className="md:mr-12 sm:mr-10 mt-2 mobile">
                <li className="pt-3 pb-3">
                  <a href="/websiteDesignandDev">
                    Website Design & Development
                  </a>
                </li>
                <li className="pb-3">
                  <a href="/softwaredevelopment">Software Development</a>
                </li>
                <li className="pb-3">
                  <a href="/visualdesign">Visual Design</a>
                </li>
                <li className="pb-3">
                  <a href="/datascience">Data Science</a>
                </li>{" "}
                <li className="pb-3">
                  <a href="/services-support">Services & Support</a>
                </li>
                <li className="pb-3">
                  <a href="/contact">Contact Us</a>
                </li>
              </ul>
            </div>

            <div className="text-white text-right md:ml-5">
              <a href="/">
                <img
                  src={Logo}
                  alt="Glasco"
                  height={300}
                  width={300}
                  className="mob-footer-logo"
                ></img>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Layout;
