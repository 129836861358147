import { render } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
const Comments = ({ item, modal, setModal }) => {
  let date = new Date().toISOString().slice(0, 10);

  return item._id === modal ? (
    <div className="min-w-screen ml-auto bg-gray-600 min-h-full m-auto transition-shadow rounded mb-2">
      <p
        className="float-right text-white pb-2 pr-2 pt-1 text-lg cursor-pointer"
        onClick={() => {
          setModal(null);
        }}
      >
        <FaTimes />
      </p>
      <p className="text-white flex justify-center flex-col text-left p-2 ">
        {item.comment.length > 0 ? (
          item.comment.map((comment) => {
            return (
              <div className="flex justify-between">
                <p className="text-xs text-yellow-300 disabled p-1">
                  {comment.split("--")[0]}
                </p>
                <div className="flex">
                  <p className="text-xs text-green-500 p-1">
                    {comment.split("  ")[0].split("--")[1]}
                  </p>
                  <p className="text-xs text-green-500 p-1">
                    {date === comment.split("  ")[1]
                      ? "Today"
                      : comment.split("  ")[1]}
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-white pb-2 pr-2 pt-1 text-xs text-center">
            No Comments
          </p>
        )}
      </p>
    </div>
  ) : (
    ""
  );
};

export default Comments;
