import "./landingStyles.css";
import Layout from "./Layout";
// import asana from "asana";

import Item1 from "../Images/Landing Page/interface-25.png";
import newItem from "../Images/XMLID_15_.png";
import Item2 from "../Images/Landing Page/GRHA-5.png";
import Item3 from "../Images/Landing Page/Home-page-bubbles4-01.png";
import Item4 from "../Images/Landing Page/Home page bubbles 4-02-02.png";
import Item5 from "../Images/Landing Page/Home page bubbles 4-03.png";
import Item6 from "../Images/Data Science/data science 2.png";
import Item7 from "../Images/Landing Page/Home page bubbles 4-04.png";
import Item8 from "../Images/Landing Page/software development-2.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";

function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout title="Home - Glasco Technologies">
      <div className="main text-white">
        <div className="grid md:grid-cols-10 md:gap-5">
          <div className="md:col-span-5 flex flex-col items-center justify-center md:mt-0">
            <div className="md:ml-44 sm:mt-5 vs ">
              <h1 className="text-xl md:text-lg">
                Seamlessly outsource design and technology projects to a
                competent team you can trust.
              </h1>
              <h1 className="text-lg mt-12">
                Tackle that new project without taking on new staff. Glasco's
                industry experienced team can help orient and fine tune your
                project vision, move it along and bring it to the finish line.
                Present us a challenge or project idea related to design,
                development or data science and discover what Glasco can do for
                you.
              </h1>
            </div>
          </div>
          <div className="flex items-center justify-center img mt-5 md:col-span-4">
            <img src={Item1} alt="item1" className="img1"></img>
          </div>
        </div>
      </div>
      <div className="next md:flex items-center justify-center">
        <div className="md:mr-10">
          <img src={Item2} alt="item2" height={500} width={500}></img>
        </div>
        <div className="md:ml-10">
          <Link to="visualdesign">
            {" "}
            <img
              src={Item3}
              alt="item3"
              height={500}
              width={500}
              className="hover:opacity-75"
            ></img>
          </Link>
        </div>
      </div>
      <div className="next md:flex items-center justify-center">
        <div className="md:mr-10">
          <Link to="websiteDesignandDev">
            <img
              src={Item4}
              alt="item4"
              height={450}
              width={450}
              className="hover:opacity-75"
            ></img>
          </Link>
        </div>
        <div className="md:ml-10">
          <img src={newItem} alt="itemimg" height={600} width={600}></img>
        </div>
      </div>
      <div className="next md:flex items-center justify-center">
        <div className="md:mr-10">
          <img
            src={Item6}
            alt="item6"
            height={500}
            width={500}
            className="hover:opacity-75"
          ></img>
        </div>
        <div className="md:ml-10">
          <Link to="datascience">
            <img
              src={Item5}
              alt="item5"
              height={500}
              width={500}
              className="hover:opacity-75"
            ></img>
          </Link>
        </div>
      </div>
      <div className="next md:flex items-center justify-center">
        <div className="md:mr-10">
          <Link to="/softwaredevelopment">
            <img
              src={Item7}
              alt="item7"
              height={500}
              width={500}
              className="hover:opacity-75"
            ></img>
          </Link>
        </div>
        <div className="md:ml-10">
          <img
            src={Item8}
            alt="item8"
            height={500}
            width={500}
            className="hover:opacity-75"
          ></img>
        </div>
      </div>
    </Layout>
  );
}

export default Landing;
