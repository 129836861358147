import "../App.css";
import Layout from "./Layout";
import jwt_decode from "jwt-decode";

import "./websiteDevstyles.css";
import Item1 from "../Images/Web Site Design _ Development/interface 21-3.png";
import newItem from "../Images/Group_904.png";
import newItem1 from "../Images/XMLID_15_ (1).png";
import newItem2 from "../Images/XMLID_15_ (2).png";
import newItem3 from "../Images/XMLID_15_ (5).png";
import newItem4 from "../Images/contactus.png";
import Item2 from "../Images/Web Site Design _ Development/XMLID_18_@2x.png";
import Item3 from "../Images/Web Site Design _ Development/Websites/GH-2.jpg";
import Item4 from "../Images/Web Site Design _ Development/Websites/CRC-2.jpg";
import Item5 from "../Images/Web Site Design _ Development/Websites/Steem-2.jpg";
import Item6 from "../Images/Web Site Design _ Development/Websites/Apollo-2.jpg";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function WebsiteDesDev() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const navigate = useNavigate();

  // const user = localStorage.getItem("glascotoken");
  // useEffect(() => {
  //   if (user) {
  //     var decoded = jwt_decode(user);
  //     if (decoded.exp * 1000 < Date.now()) {
  //       navigate("/login");
  //       console.log("expired");
  //     }
  //   }
  //   if (!user) {
  //     navigate("/login");
  //   }
  // }, [user, navigate]);
  return (
    <Layout title="Web Development - Glasco Technologies">
      <div className="main1 text-white">
        <div className="grid md:grid-cols-10 md:gap-5">
          <div className="md:col-span-5 flex flex-col items-center justify-center md:mt-0">
            <div className="md:ml-44 sm:mt-5 vs ">
              <div className="md:mb-10 iconMargin">
                <img
                  src={Item2}
                  alt="item2"
                  className="img2"
                  height={150}
                  width={150}
                ></img>
              </div>
              <h1 className="text-4xl">Website Design & Development</h1>
              <h6 className="mt-5">
                For companies competing in 2020, a dynamic website that displays
                seamlessly across all devices is an absolute necessity. Glasco
                leverages the latest techniques and development platforms to
                ensure your site always functions smoothly. Using these
                platforms, particularly React, also makes development of mobile
                apps and websites more efficient and cost effective. Let our
                team build an amazing user-friendly experience and make it easy
                for your target customers to navigate and decide to engage with
                you over and over again.
              </h6>
            </div>
          </div>
          <div className="flex items-center justify-center img mt-5 md:col-span-4">
            <img src={Item1} alt="item1" className="img1"></img>
          </div>
        </div>
      </div>
      <div className="flex flex-col  method">
        <h2 className="mt-5 text-center">Our Method</h2>
        <div className="md:flex  items-center justify-center md:mt-14 md:ml-40 md:mr-40 mb-14 method">
          <div className="flex flex-col items-center justify-center md:m-4">
            <img src={newItem} alt="item" className="img"></img>
            <h1
              className="mt-5"
              style={{
                color: "#3c4858",
                fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "1.618",
              }}
            >
              Research
            </h1>
            <h1
              className="mt-5"
              style={{
                fontFamily: "verdana, geneva, sans-serif",
                fontSize: "10pt",
              }}
            >
              Understand the client’s vision and goals. Learn the current
              competitive landscape.
            </h1>
          </div>
          <div className="flex flex-col items-center justify-center md:m-4">
            <img src={newItem1} alt="item" className="img"></img>
            <h1
              className="mt-5"
              style={{
                color: "#3c4858",
                fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "1.618",
              }}
            >
              Brainstorm
            </h1>
            <h1
              className="mt-5"
              style={{
                fontFamily: "verdana, geneva, sans-serif",
                fontSize: "10pt",
              }}
            >
              Generate a multitude of possibilities. Let ideas emerge. Evaluate
              and play with them.
            </h1>
          </div>
          <div className="flex flex-col items-center justify-center md:m-4">
            <img src={newItem2} alt="item" className="img"></img>
            <h1
              className="mt-5"
              style={{
                color: "#3c4858",
                fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "1.618",
              }}
            >
              Design the user experience
            </h1>
            <h1
              className="mt-5 marr"
              style={{
                fontFamily: "verdana, geneva, sans-serif",
                fontSize: "10pt",
              }}
            >
              Create a standout user experience that accomplishes the project
              goals.
            </h1>
          </div>
          <div className="flex flex-col items-center justify-center md:m-4">
            <img src={newItem3} alt="item" className="img"></img>
            <h1
              className="mt-5"
              style={{
                color: "#3c4858",
                fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "1.618",
              }}
            >
              Develop & Refine
            </h1>
            <h1
              className="mt-5"
              style={{
                fontFamily: "verdana, geneva, sans-serif",
                fontSize: "10pt",
              }}
            >
              Build the designs into reality. Circle back on the other stages.
              Bring it to the finish line.
            </h1>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center method">
        <h2 className="mt-5">Previous Projects</h2>
        <h6
          className="text-sm mt-10 mb-20"
          style={{ fontWeight: "500", fontFamily: "Nexa" }}
        >
          Our team’s process results in clean modern looking websites that stand
          out for their unique style and approachable user experience. Check out
          some of our recent work below.
        </h6>
        <div className="flex flex-col items-center justify-between">
          <a href="/">
            <img src={Item3} alt="item3" className="img-shadow mb-14"></img>
          </a>
          <a href="/">
            <img src={Item4} alt="item3" className="img-shadow mb-14"></img>
          </a>
          <a href="/">
            <img src={Item5} alt="item3" className="img-shadow mb-14"></img>
          </a>
          <a href="/">
            <img src={Item6} alt="item3" className="img-shadow mb-14"></img>
          </a>
        </div>
      </div>
      <div className="method flex flex-col pos mb-20 mt-14">
        <h3 className="pos">Engage With Glasco</h3>
        <a href="/contact">
          <img
            height={200}
            width={250}
            src={newItem4}
            alt="item3"
            className="pos mt-4"
          ></img>
        </a>
      </div>
    </Layout>
  );
}

export default WebsiteDesDev;
