import axios from "axios";
import React, { useEffect, useState } from "react";

import SideBar from "../Sidebar/SideBar";

const MyTasks = () => {
  const [myTasks, setMyTasks] = useState([]);
  const [loggedIn, setLoggedIn] = useState({
    loggedIn: "",
    role: "",
  });
  const name = localStorage.getItem("glasconame");

  const updateItem = () => {
    //
  };
  useEffect(() => {
    const getItemsList = async () => {
      try {
        let userId = localStorage.getItem("glascoId");
        const url = `https://glascobackend.herokuapp.com/api/auth/single/${userId}`;
        const result = await axios.get(url);
        const res = await axios.get(
          "https://glascobackend.herokuapp.com/todo/api/items"
        );
        setLoggedIn({ loggedIn: result.data.loggedIn, role: result.data.role });

        setMyTasks(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getItemsList();
  }, []);

  const filteredTasks = myTasks.filter(
    (task) => task.assignedTo === name.charAt(0).toUpperCase() + name.slice(1)
  );
  console.log("klkl", filteredTasks);
  let date = new Date().toISOString().slice(0, 10);
  const id = localStorage.getItem("glascoId");
  return loggedIn.loggedIn === true ? (
    <SideBar>
      <div className="main-container1  mt-9 md:col-span-4 lg:ml-96 m-5 ">
        <h1 className="text-4xl font-bold mb-5 text-left">My Tasks</h1>
        <div className="maiin ">
          <div className="">
            <div className="">
              {filteredTasks.length > 0 &&
                loggedIn.role === 1 &&
                filteredTasks.map((item) => {
                  return (
                    <div className="h-auto w-96 mt-2 bg-slate-300 rounded p-3 ">
                      <p className="text-green-500 min-w-full text-left font-semibold  ">
                        {item.item}
                      </p>
                      <div className="flex flex-row items-center justify-between">
                        <div>
                          <p className="text-green-500 font-bold">
                            Completed By {item.assignedTo}
                          </p>
                          <p className="text-green-500 font-semibold">
                            {date === item.updatedAt.slice(0, 10)
                              ? "Today"
                              : item.updatedAt.slice(0, 10)}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </SideBar>
  ) : (
    "Wait....."
  );
};

export default MyTasks;
