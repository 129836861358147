import React, { useEffect, useRef, useState } from "react";
import Layout from "./Layout";
import "./supportStyles.css";
import emailjs from "@emailjs/browser";

// https://www.GlascoTech.com//public/uploads/banner/full//bc486a63_hand-holding-cloud-system-with-data-protection.jpg
const Support = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [name, setName] = useState();
  const [mobile, setMobile] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_5vpfm55",
        "template_cwii4x9",
        form.current,
        "Q1pBIuRpQaXBmxV6e"
      )
      .then(
        (result) => {
          console.log(result.text);
          setName("");
          setEmail("");
          setMessage("");
          setMobile("");
          setLoading(!loading);
          alert(`Thanks ${name}, Email sent successfully !`);
        },
        (error) => {
          console.log(error.text);
          alert(error.text);
        }
      );
  };

  const howGlascoHelps = [
    {
      title: "Platform Navigator",
      subTitile:
        "Controls all the CP4i components & centralizes the management",
    },
    {
      title: "Asset Repository",
      subTitile:
        "Accelerates development through reuse of integration assets across capabilities",
    },
    {
      title: "Common Services",
      subTitile:
        "Supports logging, metering, monitoring & other key foundational services and also facilitates the transition to OpenShift",
    },
    {
      title: "API Connect",
      subTitile:
        "Help control your API ecosystem and prepare a robust API strategy that can meet the mission-critical tasks while communicating with the respective users. Supports creation, management, security, and sharing of APIs",
    },
    {
      title: "App Connect Enterprise",
      subTitile:
        "GlascoTech helps migrate from old IBM Integration Bus to official successor App Connect Enterprise. Offers integration of all your data & applications more quickly across any cloud with keeping the control on sources, formats & standards",
    },
    {
      title: "MQ Advanced",
      subTitile:
        "GlascoTech offers the end-to-end services and ensures you receive the information about what you need & when you need or to receive only once! Supports operations to simplify, accelerate & facilitate the reliable exchange of data with a flexible & secured messaging solution.",
    },
    {
      title: "Event Streams",
      subTitile:
        "Real-time delivery of messages in a more reliable, secure & robust manner and supports for event handling based on Kafka.",
    },
    {
      title: "Data Power Gateway",
      subTitile:
        "End-to-end setup of data power gateway services to manage, control, access security rich connections between your on-premises & cloud environments. Supports monitoring traffic & configuring TLS encryption",
    },
    {
      title: "Aspera High Speed Transfer Server",
      subTitile:
        "Capability to meet the demands of global team without compromising on security with Aspera. GlascoTech helps setting up Aspera high speed transfer to send files & data sets virtually anywhere at maximum speed.",
    },
  ];

  return (
    <Layout title="Support - Glasco Technologies">
      <div className="main ">
        <div className="grid md:grid-cols-12  max-w-7xl mx-auto md:p-5">
          <div className="md:col-span-7 flex flex-col items-center justify-center md:mt-0 text-white">
            <div className=" sm:mt-5 vs md:p-6 p-0">
              <h1 className="font-bold text-3xl ">
                IBM Cloud Pak for Integration (CP4i) Consulting Services &
                Support - Glasco Tech
              </h1>
              <h6 className="mt-12 text-white">
                IBM Cloud Pak for Integration (CP4i) offers a comprehensive
                suite of integration capabilities, enabling seamless
                connectivity between applications and data across multiple cloud
                environments or on-premises setups.
              </h6>
            </div>
          </div>
          <div className="flex items-center justify-center img mt-5 md:col-span-5 ">
            <form className="mt-6 mb-10 pro " ref={form} onSubmit={sendEmail}>
              <h1 className="text-2xl mb-5 text-white font-semibold text-center">
                Talk to Our Expert
              </h1>
              <div className="mb-3 flex flex-col">
                <label htmlFor="name" className="mb-0 text-white">
                  Full Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="w1 p-1"
                  name="user_name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="mb-2 text-white">
                  Email
                </label>

                <input
                  type="email"
                  id="email"
                  className="w-full p-1"
                  name="user_email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="mobile" className="mb-2 text-white">
                  Mobile
                </label>

                <input
                  type="number"
                  id="mobile"
                  placeholder="+"
                  className="w-full p-1"
                  name="user_mobile"
                  required
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="message" className="mb-2 text-white">
                  Requirement
                </label>
                <textarea
                  name="message"
                  className="w-full p-1"
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <button
                  type="submit"
                  value="Send"
                  className="primary-button w-full text-white font-extrabold"
                  onClick={() =>
                    name &&
                    mobile &&
                    email?.includes(
                      "/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/"
                    ) &&
                    message &&
                    setLoading(!loading)
                  }
                  style={{ backgroundColor: "#233F8E" }}
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className=" md:pt-20 pt-5">
        <div className=" max-w-7xl mx-auto px-6">
          <h1 className="font-bold text-4xl pb-4">
            IBM Cloud Pak for Integration (CP4i) Services & Support
          </h1>
          <div className="space-y-5">
            <p>
              IBM Cloud Pak for Integration (CP4I) is a powerful platform
              designed to facilitate quick and seamless integration of hybrid
              cloud applications with critical systems and applications, crucial
              for running your business. It promotes collaboration among
              different application teams and business units within your
              organization, ensuring maximum efficiency in their operations.
            </p>
            <p>
              By simplifying connectivity to the IBM Cloud and other external
              cloud environments, CP4I provides a variety of integration tools
              and services to connect cloud applications with on-premises
              systems, SaaS applications, and other cloud services. These tools
              include MQ, API Connect, App Connect Enterprise, DataPower, Event
              Streams (Kafka), and Aspera. CP4I plays a pivotal role in IBM's
              cloud strategy, solidifying its commitment to delivering robust
              and comprehensive integration solutions.
            </p>
            <p>
              With its powerful integration capabilities, CP4i offers a
              comprehensive and centralized solution to address all enterprise
              integration needs through IBM Cloud Integration Services. It
              simplifies the process of creating, managing, and monitoring
              integrations across various components, including applications,
              messages, events, and APIs, making it one of the leading IBM Cloud
              Integration Solutions.
            </p>
            <p>
              As a trusted partner of IBM for nearly a decade, GlascoTech boasts
              proven expertise in implementing IBM Cloud Pak for Integration
              (CP4i) and its associated components. GlascoTech is a recommended
              Business Partner, providing end-to-end support for organizations
              seeking to leverage the full potential of IBM Cloud Integration
              Services and other cloud integration solutions in their
              integration projects.
            </p>

            <div className="space-y-3">
              <h1 className="font-bold text-2xl">
                IBM Cloud Pak for Integration (CP4i) and its components
              </h1>

              <p>
                GlascoTech provides a comprehensive range of professional
                services tailored to IBM Cloud Pak for Data Integration and its
                components. Our expertise includes:
              </p>
              <ul
                style={{
                  listStyleType: "decimal",
                  paddingLeft: 30,
                }}
                className="space-y-3"
              >
                <li>
                  <span className="font-bold text-lg">
                    IBM Middleware & Integration Services:&nbsp;
                  </span>
                  We offer end-to-end configuration, installation, design,
                  development, and deployment services for IBM Integration Bus.
                  Additionally, we specialize in migrating from Message Broker
                  Version 8.0 and IIB Version 8.0 to the upgraded version 10.0.
                  We can also facilitate migration from Enterprise Service Bus
                  to Integration Bus and IIB Migration to APP Connect
                  Enterprise.{" "}
                </li>
                <li>
                  <span className="font-bold text-lg">
                    IBM App Connect Enterprise Implementation & Migration:&nbsp;
                  </span>
                  Our team provides seamless migration services from IBM IIB to
                  the official successor, App Connect Enterprise v11.0, ensuring
                  a smooth transition.
                </li>{" "}
                <li>
                  <span className="font-bold text-lg">
                    IBM API Connect Services:&nbsp;
                  </span>
                  Our offerings include consulting, managed services, migration
                  services, optimization, security, and training services for
                  IBM API Connect, empowering businesses to effectively manage
                  their APIs.
                </li>{" "}
                <li>
                  <span className="font-bold text-lg">
                    Timely Health-Checks for Integration & Migration:&nbsp;
                  </span>
                  We conduct thorough health-checks for existing applications to
                  identify opportunities for integration and migration
                  improvements, ensuring optimal performance and efficiency.
                </li>{" "}
                <li>
                  <span className="font-bold text-lg">
                    IBM WebSphere MQ Administration, Architecture &
                    Development:&nbsp;
                  </span>
                  Our end-to-end services and support cover IBM WebSphere MQ
                  administration, architecture, and development, including
                  comprehensive support on MQ assessment solutions.
                </li>{" "}
                <li>
                  <span className="font-bold text-lg">
                    IBM DataPower Gateway Appliances Implementation:&nbsp;
                  </span>
                  We excel in implementing and deploying IBM DataPower Gateway
                  Appliances, ensuring efficient control, acceleration, and
                  security of network traffic, supported by detailed roadmaps,
                  assessments, and documentation.
                </li>{" "}
                <li>
                  <span className="font-bold text-lg">
                    Aspera High-Speed Transfer Services:&nbsp;
                  </span>
                  Our expertise extends to Aspera high-speed transfer services,
                  encompassing end-to-end installation, deployment, and support
                  for accelerated and efficient data transfers.
                </li>
              </ul>
            </div>

            <div>
              <h1 className="font-bold text-2xl">
                IBM Cloud Pak for Integration (CP4i) Components & How GlascoTech
                Helps:
              </h1>
              <div
                className="bg-white   rounded-3xl my-10 lg:p-14 p-10 grid grid-cols-12 md:gap-16 "
                style={{
                  boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
                }}
              >
                {howGlascoHelps.map((item, i) => {
                  return (
                    <div className="flex flex-col items-start justify-start  space-y-2 md:col-span-4 col-span-12 my-5  md:my-3 myborder ">
                      <div className="bg-blue-500 rounded-full h-14 w-14 flex items-center justify-center">
                        <svg
                          class="w-6 h-10 text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-width="2"
                            d="M8.7 8.7c1.1-1 2.2-2 3.3-2.7m0 0c3.1-2 6-2.6 7.4-1.3 1.8 1.8 0 6.6-4 10.7-4.1 4-8.9 5.8-10.7 4C3.4 18 4 15.2 6 12m6-6C9 4 6 3.3 4.7 4.6c-1.8 1.8 0 6.6 4 10.7M12 6c1.2.7 2.3 1.7 3.4 2.7m2.7 3.4c2 3 2.6 6 1.3 7.3C18 20.7 15 20 12 18m2-6a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
                          />
                        </svg>
                      </div>
                      <h4 className="font-semibold text-xl relative ">
                        {item.title}
                      </h4>
                      <p className="py-2 text-gray-600 font-semibold">
                        {item.subTitile}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:pt-10">
        <div className="max-w-7xl mx-auto px-6 grid grid-cols-12">
          <div className="md:col-span-7 col-span-12 px-2">
            <div>
              <h1 className="font-bold text-4xl pb-4">
                Benefits of IBM Cloud Pak for Data Integration (CP4i)
              </h1>
              <div className="space-y-6">
                <div>
                  <h1 className="font-bold text-2xl pb-4">
                    Connect apps with ease
                  </h1>
                  <div className="space-y-3">
                    <p>
                      IBM Cloud Pak for Data Integration provides a seamless way
                      to connect applications with ease. With no-code
                      integration tools, users can easily build integration
                      flows without the need for extensive coding knowledge. The
                      platform offers a wide range of pre-built templates and
                      connectors tailored for the most common business
                      applications, making the integration process efficient and
                      straightforward.
                    </p>
                    <p>
                      Leveraging the power of AI, IBM Cloud Pak for Integration
                      automates time-consuming and complex tasks, further
                      simplifying the integration process.{" "}
                    </p>
                  </div>
                </div>
                <div>
                  <h1 className="font-bold text-2xl pb-4">
                    Grow and improve your APIs
                  </h1>
                  <div className="space-y-3">
                    <p>
                      The platform provides a single, unified experience to
                      create, secure, manage, and promote APIs effectively. By
                      streamlining the API lifecycle, organizations can
                      accelerate their development process and ensure the
                      highest quality APIs.
                    </p>
                    <p>
                      One of the key features of the IBM cloud integration
                      platform is its automated testing capabilities. This helps
                      organizations gain valuable insights into the performance
                      and reliability of their APIs through automated testing
                      processes.
                    </p>
                  </div>
                </div>
                <div>
                  <h1 className="font-bold text-2xl pb-4">
                    React in Real-time
                  </h1>
                  <div className="space-y-3">
                    <p>
                      IBM cloud integration platform enables businesses to
                      replace manual workflows with automated actions that
                      address customer needs instantaneously. This real-time
                      responsiveness is achieved through event-driven
                      capabilities that are triggered by both internal and
                      external events.
                    </p>
                  </div>
                </div>

                <div>
                  <h1 className="font-bold text-2xl pb-4">
                    High-Speed Data Migration
                  </h1>
                  <div className="space-y-3">
                    <p>
                      IBM cloud Pak for integration platform facilitates
                      seamless transfers to, from, and between on-premise data
                      centers and major cloud providers.
                    </p>
                  </div>
                </div>

                <div>
                  <h1 className="font-bold text-2xl pb-4">
                    Cloud Agnostic Integration
                  </h1>
                  <div className="space-y-3">
                    <p>
                      Seamlessly connecting applications and services across any
                      cloud provider. The platform is designed to work with
                      various cloud environments, ensuring compatibility and
                      ease of integration.IBM Cloud Pak for Data Integration
                      adopts a cloud-native architecture, allowing organizations
                      to deploy and scale their integration solutions with
                      flexibility and efficiency.
                    </p>
                  </div>
                </div>

                <div className="pt-20">
                  <h1 className="font-bold text-2xl pb-4">
                    GlascoTech Assessment Process
                  </h1>
                  <div className="space-y-3">
                    <p>
                      GlascoTech follows a comprehensive assessment process to
                      ensure that clients' systems and applications are
                      thoroughly evaluated. Our standard assessment process
                      involves the following steps:
                    </p>
                    <ul
                      style={{
                        listStyleType: "decimal",
                        paddingLeft: 20,
                      }}
                      className="space-y-3"
                    >
                      <li>
                        <span className="font-bold text-lg">
                          Sizing & Capacity Evaluation:&nbsp;
                        </span>
                        We assess the system's sizing and capacity to determine
                        if it aligns with the organization's requirements and
                        future growth. By analyzing the current infrastructure
                        and workloads, we recommend appropriate sizing and
                        capacity adjustments to optimize performance.
                      </li>
                      <li>
                        <span className="font-bold text-lg">
                          Security Assessment:&nbsp;
                        </span>
                        Our team conducts a thorough security assessment to
                        identify any potential vulnerabilities or security
                        issues. We examine the existing security measures and
                        policies, recommending improvements to enhance the
                        overall security posture.
                      </li>{" "}
                      <li>
                        <span className="font-bold text-lg">
                          Performance Evaluation: &nbsp;
                        </span>
                        We analyze the system's performance to identify any
                        bottlenecks or areas that may impact performance. Our
                        team looks into various aspects, such as response times,
                        latency, and resource utilization, providing
                        recommendations to optimize performance.
                      </li>{" "}
                      <li>
                        <span className="font-bold text-lg">
                          Memory Leak Detection:&nbsp;
                        </span>
                        We perform memory leak detection to identify any
                        memory-related issues that may cause performance
                        degradation. Detecting and resolving memory leaks is
                        critical for ensuring system stability and resource
                        optimization.
                      </li>{" "}
                      <li>
                        <span className="font-bold text-lg">
                          Right Consultation:&nbsp;
                        </span>
                        Throughout the assessment process, our experts provide
                        the right consultation to address specific challenges
                        and provide actionable insights to improve the system's
                        overall health and performance.
                      </li>{" "}
                      <li>
                        <span className="font-bold text-lg">
                          IBM & Red Hat Best Practices:&nbsp;
                        </span>
                        GlascoTech follows industry best practices recommended
                        by IBM and Red Hat, ensuring that the assessment process
                        adheres to the highest standards and aligns with the
                        best practices for system optimization and security.
                      </li>{" "}
                      <li>
                        <span className="font-bold text-lg">
                          Recommendations:&nbsp;
                        </span>
                        Based on the assessments, we provide a detailed report
                        with recommendations tailored to the organization's
                        needs. These recommendations encompass actionable steps
                        to address issues and optimize the system's performance.
                      </li>
                      <li>
                        <span className="font-bold text-lg">
                          Identification of Issues, Errors & Omissions:&nbsp;
                        </span>
                        The assessment process meticulously identifies any
                        existing issues, errors, or omissions within the system.
                        By providing a comprehensive list of these areas, we
                        enable organizations to address them promptly for
                        enhanced system efficiency.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:col-span-5 col-span-12">
            <img
              src="https://www.techzert.com//public/uploads/pages/full/3a3e30a4_feat-img.png"
              alt="img"
            />
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-6 py-6 ">
          <h1 className="font-bold text-4xl pb-4">
            GlascoTech's Result-Oriented Approach on Cloud Pak for Integration
            (CP4i) Services
          </h1>
          <p className="font-bold">
            GlascoTech adopts a result-oriented approach to IBM Cloud Pak for
            Integration (CP4i) services, providing a comprehensive bundle of
            services and key strategies to ensure successful deployment and
            integration.
          </p>
          <ul
            style={{ listStyleType: "disc", paddingLeft: 20 }}
            className="py-5 space-y-1 font-semibold  text-gray-600"
          >
            <li>
              Provides a bundle with comprehensive services & key strategies to
              deliver successful deployment
            </li>
            <li>Performance Tuning</li>
            <li>Comprehensive Customer Support</li>
            <li>Monitoring & Troubleshooting</li>
            <li>Achieve any-to-any integration</li>
            <li>Integration Platform Management (ESB/SOA/B2B), iPaaS</li>
            <li>
              API Management Marketplace, Monetization, and Business Process
              Integration
            </li>
            <li>
              ACE Design & Development, Administration, Proof of Concept, App
              Connect Implementation on-prem & cloud
            </li>
            <li>IBM ACE v11.0 Fix Pack Upgrade & Testing</li>
            <li>Implementation & Performance Tuning</li>
            <li>
              Regular health check assessments on performance, compliance,
              security, availability, auditability, disaster recovery,
              flexibility, consistency, scalability, scripts, automation, and
              upgrades
            </li>
            <li>Data Power Appliance Setup, Administration & Support</li>
            <li>
              Prepares strategy-driven solution on automated deployments using
              DevOps & API governance
            </li>
            <li>On-premises & cloud setup</li>
            <li>Developer Portal customization, migration & Monitoring</li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default Support;
